@import "../../mixins.scss";

.cci {

  .lateralBarView {
    height: 100vh;
    background: #005397 !important;
    color: white;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    position: fixed;
    //width: 120px;
    width: 86px;
    align-items: center;
    padding-top: 20px;
    justify-content: space-between;

    .buttons {
      @media(max-width: 425px){
        display: flex;
      }
    }

    .menu-search {
      display: none;
    }

    .numero-lateral {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //height: calc(100vh - 86px);
      .byridmi {
        height: 100px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        i {
          display: block;
          transform: rotate(-90deg);
          color: white;
          .path1, .path2, .path3{
            &:before {
              color: white;
            }
          }
        }
      }
      .logo-cci {
        height: 200px;
        justify-content: center;
        align-items: center;
        display: flex;
        img {
          max-height: 1.9rem;
          transform: rotate(-90deg);
        }

        i {
          display: block;
          transform: rotate(-90deg);
          color: white;
          font-size: 1.1rem;
          .path1, .path2{
            &:before {
              color: white;
            }
          }
        }
      }
    }

    button.menu-numero-toggle-btn {
      width: 50px;
      height: 50px;
      border: 0 !important;
      margin: 0;
      background: #00437A;
      transition: all 0.2s ease-out;
      border-radius: 5px;
      transform: translateX(0px);
      padding: 0;
      cursor: pointer;
      margin-bottom: 20px;
      display: flex;
      display: none;
      justify-content: center;
      align-items: center;

      @media (max-width: 425px) {
        width: 45px;
        height: 45px;
      }

      i {
        color: white;
        font-size: 1.3rem;
      }

      @at-root body:not(.touch) #{&} {
        &:hover {
          background-color: #08BADF;
          transition: all 0.4s ease-out;
        }
      }

      &.active {
        background-color: #08BADF;
        transition: all 0.4s ease-out;
      }

    }

    button.searchBtn {
      width: 50px;
      height: 50px;
      border: 0 !important;
      margin: 0;
      background: #00437A;
      transition: all 0.2s ease-out;
      border-radius: 5px;
      transform: translateX(0px);
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 425px) {
        width: 45px;
        height: 45px;
      }

      &.isSearching {
        background-color: #08BADF;
        transition: all 0.4s ease-out;
        i{
          color: white;
        }
      }

      i {
        color: white;
        font-size: 1.3rem;
      }
    }

    button.menu-toggle-btn {
      width: 50px;
      height: 50px;
      border: 0 !important;
      margin: 0;
      background: #00437A;
      transition: all 0.2s ease-out;
      border-radius: 5px;
      transform: translateX(0px);
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 425px) {
        width: 45px;
        height: 45px;
      }

      i {
        color: white;
        font-size: 1.3rem;
      }

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg.menu-icon-on {
        //transform: scale(0.75) translate(-3px, -4px);
      }

      g {
        rect {
          transition: all 0.25s ease-out;
        }
      }

      &.menuShow {
        background-color: #08BADF;
        transition: all 0.4s ease-out;

        /*.menu-icon-on {
          g {
            rect{
              &:nth-child(1){
                transform: rotate(30deg) translate(2px, 0px);
                transition: all 0.17s ease-in-out 0.10s;
              }
              &:nth-child(2){
                transform: rotate(-30deg) translate(-6px, 6px);
                transition: all 0.17s ease-in-out 0.10s;
              }
              &:nth-child(3){
                transform: scaleX(0);
                transition: all 0.25s ease-in;
              }
            }
          }
        }*/


      }

      @at-root body:not(.touch) #{&} {
        &:hover {
          background-color: #08BADF;
          transition: all 0.1s ease-out;
          border-bottom: 0;
        }
      }

    }

    .numero-info {
      position: relative;
      flex-basis: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
      height: auto;
      padding: 1em;
      margin-top: 0;

      .numeroInfo {
        transform: rotateZ(180deg);
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: black;
        font-size: 0.68rem;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        a.ridmi-link {
          transform: rotate(180deg);
          margin-top: 1rem;
          .logo {
            background-image: url(../components/assets/byridmi_white.svg);
            width: 112px;
            height: 60px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }

        span.date {
          writing-mode: vertical-rl;
          position: relative;
          color: white;

          &:after {
            @include wait(darken(#257FBC, 5%));
          }

        }

        span.number {
          writing-mode: vertical-rl;
          margin-top: 3rem;
          font-weight: 500;
          //letter-spacing: 5px;
          position: relative;
          color: white;

          &:after {
            @include wait(darken(#257FBC, 5%));
          }

        }

      }

    }

    .numero-socials {

    }

    .socials {

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0.7rem;

        &:after {
          @include wait(darken(#257FBC, 5%));
        }

        li {
          margin: 0.5rem;
          position: relative;

        }

      }

      a {
        color:  white;

        span {
          text-indent: -9999px;
          display: block;
          overflow: hidden;
          width: 0;
          height: 0;
        }

      }

    }

  }

}

@import "./media-lateral-bar.scss";
