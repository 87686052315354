@font-face {
  font-family: 'icomoon-cci';
  src:  url('icomoon-cci.eot?47cxjj');
  src:  url('icomoon-cci.eot?47cxjj#iefix') format('embedded-opentype'),
  url('icomoon-cci.ttf?47cxjj') format('truetype'),
  url('icomoon-cci.woff?47cxjj') format('woff'),
  url('icomoon-cci.svg?47cxjj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-cci-"], [class*=" ico-cci-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-cci' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-cci-arrow-cci:before {
  content: "\e900";
}
.ico-cci-arrow-left-rounded:before {
  content: "\e901";
}
.ico-cci-arrow-left:before {
  content: "\e902";
}
.ico-cci-arrow-right-rounded:before {
  content: "\e903";
}
.ico-cci-arrow-right:before {
  content: "\e904";
}
.ico-cci-byridmi .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.ico-cci-byridmi .path2:before {
  content: "\e906";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-cci-byridmi .path3:before {
  content: "\e907";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-cci-CCI .path1:before {
  content: "\e908";
  color: rgb(0, 0, 0);
}
.ico-cci-CCI .path2:before {
  content: "\e909";
  margin-left: -6.63671875em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.ico-cci-facebook:before {
  content: "\e90a";
}
.ico-cci-instagram:before {
  content: "\e90b";
}
.ico-cci-kiosque:before {
  content: "\e90c";
}
.ico-cci-menu:before {
  content: "\e90d";
}
.ico-cci-next-article:before {
  content: "\e90e";
}
.ico-cci-prev-article:before {
  content: "\e90f";
}
.ico-cci-progress-dot:before {
  content: "\e910";
}
.ico-cci-progress:before {
  content: "\e911";
}
.ico-cci-read-circle:before {
  content: "\e912";
}
.ico-cci-read-public:before {
  content: "\e913";
}
.ico-cci-read:before {
  content: "\e914";
}
.ico-cci-ridmi .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.ico-cci-ridmi .path2:before {
  content: "\e916";
  margin-left: -3em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-cci-search:before {
  content: "\e917";
}
.ico-cci-time:before {
  content: "\e918";
}
.ico-cci-triangle-intro:before {
  content: "\e919";
}
.ico-cci-twitter:before {
  content: "\e91a";
}
