.ie-support {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 100000000000000000;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 900px;

    .logo {
      img {
        width: 300px;
      }
      margin-bottom: 2.5rem;
    }

    a {
      font-weight: bold;
      color: #242424;
      text-decoration: underline !important;
      &:hover {
        color: black;
      }
    }
    p {
      text-align: center;
      width: 100%;
    }

  }

}
