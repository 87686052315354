@import "../../../index.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000000000000000000000000000 !important;
}

q {
  font-style: italic;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

}

p {
  line-height: 1.66rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: 1rem;
}

main, .main {
  overflow: visible !important;
  //z-index: 0;
}

a {
  color: black;
  text-decoration: none !important;

  &:hover, &:focus {
    text-decoration: none !important;
  }

}

button, button:focus, button:active, button:hover{
  outline: none !important;
  border: none !important;
}

@media (max-width: 1280px) {
  html {
    font-size: 17px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media (min-height: 1000px) and (orientation: portrait){
  html {
    font-size: 20px;
  }
}

@media (max-width: 769px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 425px)
{
  html {
    font-size: 15px;
  }
}

@media (max-width: 425px)
{

  html {
    font-size: 13px;
  }

}


html{
  *[data-text='true'] {
    overflow: hidden;
    &:after {
      transform: scale(1);
      opacity: 1;
      transform-origin: top left;
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1000000000000000 !important;
}

html.wf-active {

  *[data-text='true'] {
    &:after {
      //transform: scaleX(0);
      opacity: 0;
      transform: scale(0);
      transition: opacity .35s ease, transform 0.1s 0.35s;
    }
    opacity: 1;
    transition: opacity 0.35s ease;
  }

  *[data-fade='true'] {
    opacity: 1 !important;
    transition: all 1s ease;
  }

}
