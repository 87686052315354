@import "../../mixins.scss";

.cci {
  .couverture {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(47deg, #242F4D 0%, #005397 100%);

    .couvertureInner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;

      .icon3 {
        font-size: 20rem;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, 30%);
        z-index: 0;
        color: rgba(#19223B, 0.3);

        @media (max-width: 425px) {
          transform: translate(-50%, -24%);
        }
      }

      @media(max-width: 1024px){
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media(max-width: 1024px){
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-right: 2rem;
        //height: 90vh;
      }

      .couvertureInfos {
        //transform: translateX(-1.4rem);
        padding-left: 2.5rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        //max-width: 350px;
        max-width: 400px;
        position: relative;
        z-index: 1;

        .icon2 {
          font-size: 7rem;
          position: absolute;
          top: 50%;
          right: -4rem;
          transform: translateY(-50%);
          z-index: -1;
          color: #005397;

          @media(max-width: 425px){
            transform: translate(0%, -100%);
          }
        }

        @media (max-width: 1024px) {
          align-items: center;
          text-align: center;
        }

        @media(max-width: 1024px){
          position: absolute;
          bottom: 3rem;
          left: 0;
          padding-left: 2rem;
          transform: translateX(0);
          padding-right: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;
          max-width: initial;
          //align-items: center;
        }

        @media(max-width: 425px){
          align-items: stretch;
          text-align: left;
        }

        a.start-btn {
          background: #5C2483;
          display: block;
          background: #5C2483;
          display: flex;
          padding: 1rem 1.5rem;
          margin-top: 1rem;
          color: white;
          position: relative;

          @media(max-width: 425px) {
            margin: 0 auto;
            padding: 1.1rem 1.5rem;
            margin-top: 2rem;
            width: 73vw;
            div {
              width: 100%;
              text-align: center;
            }
          }

          span {
            color: white;
            font-weight: 600;
            @media(max-width: 425px) {
              padding-left: 0;
            }
          }

          &:hover {
            &:after {
              transform: translate(0px, 0px);
              transition: all 0.2s ease;
            }
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #230F30;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(10px, 10px);
            z-index: -1;
            transition: all 0.1s ease;
          }

        }

        h1 {
          color: white;
          text-transform: uppercase;
          font-size: 3.2rem;
          line-height: 3.5rem;
          margin-bottom: 0.7rem;

          @media(max-width: 1180px) {
            font-size: 2.5rem;
            line-height: 2.9rem;
          }

          @media(max-width: 1024px) {
            font-size: 2rem;
            line-height: 2.2rem;
            padding-left: 1rem;

            br {
              display: none;
            }
          }
        }
        span {
          color: #08BADF;
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.3rem;
          @media(max-width: 1024px) {
            padding-left: 1rem;
            font-size: 1.1rem;
          }
        }
      }

      .couvertureImage {
        max-width: 464px;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        max-height: 634px;
        width: 100%;
        border-radius: 5px;
        background-color: #0b4881;
        position: relative;

        @media (min-height: 900px) and (max-width: 1025px) {
          max-width: 72%;
        }

        @media(max-width: 425px) {
          max-height: 52vh !important;
          max-width: 73vw;
        }

        .icon1 {
          font-size: 4.4rem;
          position: absolute;
          bottom: 1em;
          left: 0;
          transform: translateX(-50%);
          z-index: 1;
          color: #08BADF;

          @media(max-width: 425px){
            transform: translate(-20%, -150%) scale(1.5);
          }
        }

        @media(max-width: 1024px){
          max-height: 60vh;
          //max-width: 80vw;
        }

        .imageOuter {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            will-change: opacity;
            opacity: 0;

            &.loaded {
              opacity: 1;
              transition: opacity 0.3s ease 0.300s;
            }
          }
        }
      }
    }

  }
}

@import "./couverture-media.scss";
