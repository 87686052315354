$red: #E20613;
$greylight: #f1f1f1;
$turquoise: #35B6B1;

@mixin wait($color) {
  content: "";
  background: $color;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
