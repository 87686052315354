@font-face {
  font-family: 'icomoon-original';
  src:  url('icomoon-original-cci.eot?xmo4rz');
  src:  url('icomoon-original-cci.eot?xmo4rz#iefix') format('embedded-opentype'),
  url('icomoon-original-cci.ttf?xmo4rz') format('truetype'),
  url('icomoon-original-cci.woff?xmo4rz') format('woff'),
  url('icomoon-original-cci.svg?xmo4rz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-original-"], [class*=" ico-original-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-original' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-original-lu:before {
  content: "\e906";
}
.ico-original-non_lu:before {
  content: "\e908";
}
.ico-original-time:before {
  content: "\e90b";
}
.ico-original-close:before {
  content: "\e90c";
}
.ico-original-burger:before {
  content: "\e900";
}
.ico-original-chevron-down:before {
  content: "\e901";
}
.ico-original-chevron-left:before {
  content: "\e902";
}
.ico-original-chevron-right:before {
  content: "\e903";
}
.ico-original-chevron-up:before {
  content: "\e904";
}
.ico-original-next-article:before {
  content: "\e905";
}
.ico-original-prev-article:before {
  content: "\e907";
}
.ico-original-search:before {
  content: "\e909";
}
.ico-original-reading:before, .ico-original-start-reading:before {
  content: "\e90a";
}
