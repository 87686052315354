.cci {
  .menuNumero {
    //z-index: 130000;
    //top: 0;
    //height: 100vh;
    border-left: 0 !important;
    background-color: white;
    //position: fixed;
    display: none;
    left: 86px;
    width: 100%;
    padding-top: 2rem;
    padding-left: 2rem;
    overflow: hidden;
    padding-right: 2rem;
    padding-bottom: 5rem;
    //overflow: auto;
    //-webkit-overflow-scrolling: touch;

    @media (min-height: 900px) and (max-width: 1025px) {
      left: 72px;
      width: 100%;
      //width: calc(100vw - 72px);
    }

    @media(max-width: 425px) {
      width: 100vw;
      left:0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      padding-top: 0;
      padding-bottom: 4rem;
    }

    .header-kiosque {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      width: 100%;
      position: relative;

      @media (max-width: 425px) {
        margin-bottom: 1.3rem;
      }

      .left-bloc {
        width: 100%;
        min-height: 300px;
        overflow: hidden;
        border-radius: 5px;
        height: 38vh;
        max-height: 490px;

        @media (max-width: 425px) {
          width: 100%;
          border-radius: 0;
          min-height: auto;
          height: 236px;
        }
        .inner {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .right-bloc {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        color: #242F4D;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        padding-left: 3rem;
        height: 100%;

        @media (max-width: 768px) {
          padding-left: 2rem;
        }

        @media (max-width: 425px) {
          width: 100%;
          position: absolute;
          height: 100%;
          align-items: center;
        }

        h1 {
          font-weight: 300;
          font-size: 3rem;
          line-height: 3rem;
          text-transform: uppercase;
          margin-bottom: 0.3rem;
          color: white;
          @media (max-width: 425px) {
            color: white;
            font-size: 3.5rem;
            line-height: 3.5rem;
            margin-bottom: 0.9rem;
          }
        }
        span {
          font-size: 1.8rem;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 1.7rem;
          color: white;
          @media (max-width: 425px) {
            color: white;
          }
        }
      }
    }

    circle:first-child {
      fill: transparent;
      stroke: #5C2483;
      stroke-width: 3;
      stroke-dasharray: 250;
      stroke-dashoffset: 250;
      transform: scale(-1, -1) translate(-100%, -100%);
      //transform: scale(-1, -1) translate(-42px, -42px);
    }

    .menuNumeroFilter {
      display: flex;
      flex-direction: column;

      @media(max-width: 425px){
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1.2rem;
      }

      .filter {
        display: flex;
        flex-direction: row;
        //margin-bottom: 2rem;
        margin-bottom: 1rem;

        @media(max-width: 425px) {
          width: 100%;
          overflow-x: scroll;
          margin-bottom: 1rem;
          display: -webkit-inline-box;

          &:after {
            content: "";
            width: 3.5rem;
            display: block;
          }
        }

        .typePublication {
          margin-right: 3rem;
          cursor: pointer;

          @media(max-width: 425px) {
            margin-right: 3.5rem;
            &:nth-child(1){
              margin-left: calc(1rem + 9px);
            }
            &:last-child{
              margin-right: 0;
            }
          }

          span {
            font-weight: 400;
            @media (max-width: 425px) {
              font-size: 1.3rem;
            }
          }
          &.current {
            span {
              font-weight: 600;
            }
          }
        }
      }

      @keyframes fadeInNumero {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .menuNumeros {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;
        opacity: 0;
        animation: fadeInNumero 0.3s ease forwards 0.2s;

        @media(max-width: 425px) {
          margin-left: 0;
          margin-right: 0;
          padding-left: calc(1rem + 9px);
          padding-right: calc(1rem + 9px);
          flex-wrap: nowrap;
          overflow-y: visible;
          overflow-x: scroll;
          display: -webkit-box;
          padding-top: 0.6rem;
        }

        .numeroInList {
          width: calc(100% / 6);
          padding: 1rem;
          position: relative;
          transition: all 0.1s ease-out;

          @media (max-width: 1300px){
            width: calc(100% / 4);
          }

          @media (min-height: 900px) and (max-width: 1500px){
            width: calc(100% / 5);
          }

          @media (min-height: 900px) and (max-width: 1300px){
            width: calc(100% / 4);
          }

          @media (min-height: 900px) and (max-width: 968px){
            width: calc(100% / 2);
          }

          @media (min-height: 900px) and (max-width: 768px){
            width: calc(100% / 2);
          }

          @media(max-width: 425px){
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            width: 72%;
            margin-right: 2rem;

            /*&:nth-child(2n - 1){
              padding-right: 7px;
            }
            &:nth-child(2n){
              padding-left: 7px;
            }*/
          }

          &:hover {
            transform: translateY(-4px);
            transition: all 0.3s ease-out;
          }

          &.en_cours {
            .numeroInListOuter {
              border-color: #5C2483;
            }
            .icon {
              border-color: #E4E4E4 !important;
              color: #5C2483;
            }
          }

          &.non_lu {
            .numeroInListOuter {
              border-color: #E4E4E4;
            }
            .icon {
              border-color: #E4E4E4 !important;
              color: #E4E4E4;

              i {
                font-size: 0.7rem !important;

                @media (max-width: 425px) {
                  font-size: 1rem !important;
                }

              }
            }
          }

          &.lu {
            .numeroInListOuter {
              border-color: #08BADF;
            }
            .icon {
              border-color: #08BADF !important;
              background: #08BADF !important;
              color: white;
            }
          }

          .numeroInfo {
            display: flex;
            flex-direction: row;
            margin-top: 0.8rem;

            @media (max-width: 425px) {
              font-size: 1.25rem;
            }

            .timing {
              margin-left: 1rem;
              display: flex;
              align-items: center;
              color: lighten(#000000, 70%);

              i {
                margin-right: 0.3rem;
              }

              span {
                color: black;
                font-weight: 400;
              }

            }

            .status {
              display: flex;
              align-items: center;
              color: lighten(#000000, 70%);
              .inner {
                display: flex;
                align-items: center;

                span {
                  color: black;
                  font-weight: 400;
                }

                &.lu {
                  i {
                    color: #08BADF;
                  }
                }

                &.lu, &.en_cours {
                  span {
                    font-weight: 500;
                  }
                }

              }
              i {
                margin-right: 0.3rem;
                &.ico-original-lu {
                  color: #08BADF;
                }
                &.ico-cci-progress {
                  color: #5C2483;
                }
              }
            }

            .lecture-en-cours {
              background: #5C2483;
              border-radius: 30px;
              padding: 0.15rem;
              padding-right: 1.3rem;
              padding-left: 0.8rem;
              text-transform: uppercase;
              color: white;
              top: -3px;
              right: 20px;
              z-index: 0;
              font-weight: 600;
              font-size: 0.62rem;
              position: absolute;
              transform: translateY(50%);

              @media (max-width: 425px) {
                font-size: 0.95rem;
                top: -8px;
                right: 8px;
              }

            }
            .iconsStatus {
              display: flex;
              position: absolute;
              flex-direction: row;
              top: 1rem;
              right: 1rem;
              font-size: 0.8rem;
              padding: 0.2rem 0.9rem;
              transform: translateY(-50%) translateX(50%);
              z-index: 1;

              @media (max-width: 425px) {
                top: 1rem;
                right: 0.1rem;
              }

              .icon {
                width: 34px;
                height: 34px;
                background: white;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border:solid 3px black;
                padding: 0.3rem;

                svg {
                  position: absolute;
                }

                i {
                  @media (max-width: 425px) {
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          .numeroInListOuter {
            border: 3px solid #08BADF;
            border-radius: 5px;

            .numeroInListInner {

              a {
                display: block;
                padding: 0.9rem;
                &:hover {
                  //box-shadow: 0 5px 30px 0 rgba(0,0,0,0.10);
                  color: #000;
                }

                .numeroInListInnerLink {

                }

              }
              span.title {
                margin-top: 0.8rem;
                display: block;
                max-width: 165px;
                font-weight: 400;

                @media (max-width: 425px) {
                  font-size: 1.5rem;
                }
              }
            }
          }

          .numeroInListInnerLink {
            overflow: hidden;
          }

          .couvertureKiosque {
            background-color: #ededed;
            width: 100%;
            background-size: cover;
            background-position: center center;
            transition: all 0.1s ease;
            transform-origin: center;
          }

        }
      }
    }

    &.visible {
      visibility: visible;
    }

    &.active {
      display: block;
    }
  }
}
