@import "../../mixins.scss";

.cci {
  .search-view {
    background: white;
    padding: 3rem;
    padding-top: 9rem;
    display: none;
    height: 100vh;
    //width: calc(100vw - 86px);
    width: 100%;
    //will-change: opacity;

    @media (max-width: 1025px) and (min-height: 900px) {
      left: 72px;
      //width: calc(100vw - 72px);
    }

    .spinner {
      img {
        width: 1.05rem;
        margin-right: 0.5rem;
      }
    }

    @media(max-width: 425px) {
      width: 100vw;
      left:0;
      //padding: 15px;
      padding: 1.7rem;
    }

    &.toggled {
      display: block;
    }

    > .inner {
      //opacity: 0;
      //will-change: opacity;
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #E1E1E1;
    }

    &::-webkit-scrollbar-thumb {
      background: #000000;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }

    .close-search {
      position: fixed;
      top: 0;
      right: 0;
      height: 87px;
      padding-right: 1.7rem;
      display: flex;
      align-items: center;
    }

    .searchResults {

      @media(max-width: 425px) {
        padding-bottom: 4rem;
      }

      .inner {
        max-width: 700px;
        margin: 0 auto;

        @keyframes opacityEntrySearch {
          0% {
            opacity: 0;
            transform: translateY(-20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }

        .article-result {
          margin-bottom: 2rem;
          border-bottom: solid 1px #D8D8D8;
          padding-bottom: 1.5rem;
          animation: opacityEntrySearch 0.6s ease;

          .numero {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 0.9rem;
            margin-bottom: 0.7rem;
          }

          &:hover {
            color: #257FBC !important;
            a {
              color: #257FBC !important;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }

        .title {
          text-transform: uppercase;
          font-size: 2.1rem;
          margin-bottom: 1.6rem;
          line-height: 2.2rem;
          font-weight: 400;
          display: inline-block;
        }

        .description {
          font-size: 1.1rem;
          // display: flex;
          display: inline-block;

          p {
            line-height: 2rem;
          }

        }

        span.surlign {
          display: inline-block;
          position: relative;
          //z-index: -1;
          z-index: 0;
          margin-left: 1px;
          padding-left: 3px;
          margin-right: 1px;
          padding-right: 3px;
          color: white;
        }

        span.surlign {
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            background: #08BADF;
            width: 100%;
            color: $red;
            bottom: 0;
            height: 100%;
            padding-left: 3px;
            padding-right: 3px;
            margin-left: 1px;
            margin-right: 1px;
            transform: translateY(0%);
          }
        }

      }
    }

    .searchBox {
      font-size: 3.2rem;
      border-bottom: solid 1px black;
      margin-bottom: 3rem;
      line-height: 4rem;
      transition: all 0.2s ease;
      will-change: margin-left, margin-top, margin-right;
      color: lighten(black, 70%);

      .inner {
        display: flex;
        flex-direction: column;
      }

      @media (max-width: 425px) {
        line-height: 7rem;
        margin-top: 5rem;
      }

      span.results-number {
        color: #08BADF;
        //text-transform: lowercase !important;
        font-size: 0.95rem;
        letter-spacing: 1px;
        line-height: 1.1rem;
        display: inline-block;
        overflow: hidden;
        opacity: 0;
        transform: translateY(12px);
        transition: all 0.1s ease;
        margin-bottom: 0.1rem;
        text-transform: uppercase !important;
        font-weight: 600;

        span {
          text-transform: uppercase !important;
        }

        @media (max-width: 425px) {
          font-size: 1.1rem;
          line-height: 1.3rem;
          margin-bottom: 0.7rem;
        }

        &.active {
          opacity: 1;
          transform: translateY(0);
          transition: all 0.4s ease 0.15s;
        }

      }

      &.start {
        margin-left: 8rem;
        margin-right: 8rem;
        //margin-top: 19vh;
        margin-top: 11vh;

        @media (max-width: 1025px) and (min-height: 900px) {
          margin-left: 5rem;
          margin-right: 5rem;
        }

      }

      @media(max-width: 425px) {

        &.start {
          padding-top: 0;
          margin-left: 15px;
          margin-right: 15px;
          margin-top: 10vh;
        }

      }

      input {
        text-transform: uppercase;
        width: 100%;
        border: 0;
        outline: none;
        color: black;
        margin-bottom: 0.5rem;
        font-weight: 500;

        &::placeholder {
          color: lighten(black, 70%);
        }

        @media(max-width: 1024px) {
          padding-left: 0;
          padding-bottom: 0;
          font-size: 2.6rem;
          line-height: 2.6rem;
        }

        @media(max-width: 425px) {
          padding-left: 0;
          outline: none;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 2.7rem;
          line-height: 3rem;
        }

      }

    }

  }
}
